export default defineAppConfig({
    ui: {
        primary: 'ocean',
        notifications: {
            position: 'top-0 bottom-auto right-0 left-auto',
        },
        strategy: 'override',
        checkbox: {
            background: 'bg-white',
            color: 'text-ocean-950',
        },
        input: {
            color: {
                white: {
                    outline: 'ring-1 focus:ring-2 ring-ocean-950 focus:ring-ocean-1000',
                }
            }
        },
        button: {
            color: {
                white: {
                    solid: 'bg-ocean-950 hover:bg-ocean-1000 text-white',
                }
            },
            default: {
                variant: 'solid',
                color: 'white',
            }
        }
    }
})
